
.image-container {
    position: relative;
  }
  
  .image-container::before {
    content: '×'; 
    position: absolute;
    top: -110px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: rgb(15, 14, 14);
    color: white;
    font-size: 16px;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    cursor: pointer;
  }
  .image-containerr {
    position: relative;
  }
  
  .image-containerr::before {
    content: '×'; 
    position: absolute;
    top: -210px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: rgb(15, 14, 14);
    color: white;
    font-size: 16px;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    cursor: pointer;
  }